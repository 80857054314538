import { InjectionToken } from '@angular/core';

export class AppConfig {
    api_url: string;
    assets_url: string;
    env_name: string;
    production: boolean;
    firebase_config: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
    };
    enableIntercomChat: boolean;
    intercomId: string;
    mandatoryLoginDomain: string[];
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
