import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { registerLocaleData } from '@angular/common';
import {
    ASSETS_URL,
    ButtonModule,
    ToastModule,
} from '@fintecture-npm/angular-ui';

import en from '@angular/common/locales/en-GB';
import fr from '@angular/common/locales/fr';
import es from '@angular/common/locales/es';
import { SharedModule } from './shared/shared.module';
import { FeatureFlagsInterceptor } from './shared/interceptors/http.interceptor';

import { APP_CONFIG, AppConfig } from '../app.config';
import { getAuth, provideAuth } from '@angular/fire/auth';

registerLocaleData(en, 'en');
registerLocaleData(fr, 'fr');
registerLocaleData(es, 'es');

@NgModule({
    declarations: [AppComponent],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ButtonModule, // Load here to include LoadingInterceptor
        ToastModule, // Load here to include ApiErrorsService
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        {
            provide: ASSETS_URL,
            useFactory: (config: AppConfig) => config.assets_url,
            deps: [APP_CONFIG],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FeatureFlagsInterceptor,
            multi: true,
        },
        provideFirebaseApp((injector) => initializeApp(injector.get(APP_CONFIG).firebase_config), [APP_CONFIG]),
        provideAuth(() => getAuth()),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
