export class PaymentStatus {
    static readonly SESSION_STATUS = {
        SESSION_INITATED: 'session_initiated',
        PROVIDER_REQUIRED: 'provider_required',
        SCA_REQUIRED: 'sca_required',
        CONFIRMATION_REQUIRED: 'confirmation_required',
        PAYMENT_CREATED: 'payment_created',
        PAYMENT_UNSUCCESSFUL: 'payment_unsuccessful',
        PAYMENT_PENDING: 'payment_pending',
        PAYMENT_PENDING_IMMEDIAT_TRANSFER: 'payment_pending_immediat_transfer',
        PAYMENT_PENDING_SMART_TRANSFER: 'payment_pending_smart_transfer',
        PAYMENT_ERROR: 'payment_error',
        PAYMENT_UNKNOWN: 'payment_unknown',
        PAYMENT_CANCELLED: 'payment_cancelled',
        PAYMENT_WAITING: 'payment_waiting',
        IBAN_REQUIRED: 'iban_required',
        IBAN_RECEIVED: 'iban_received',
        PAYMENT_EXPIRED: 'payment_expired',
        ORDER_CREATED: 'order_created',
        PENDING_VALIDATION: 'pending_validation',
        PAYMENT_PARTIAL: 'payment_partial',
        PAYMENT_OVERPAID: 'payment_overpaid',
        PAYMENT_COVERED: 'payment_covered',
        PAYMENT_CLAIMED: 'payment_claimed',
        PAYMENT_LATE: 'payment_late',
        PAYMENT_PLANNED: 'payment_planned',
        REFUND_WAITING: 'refund_waiting',
        FRAUD_SHIELD: 'fraud_shield',
        ENABLED: 'enabled',
        DISABLED: 'disabled'
    };

    static readonly BNPL_STATUS = {
        PAYMENT_PLANNED: 'payment_planned'
    };

    static readonly SUCCESS_STATUS = [
        PaymentStatus.SESSION_STATUS.PAYMENT_CREATED,
        PaymentStatus.SESSION_STATUS.PAYMENT_COVERED,
        PaymentStatus.SESSION_STATUS.IBAN_RECEIVED,
        PaymentStatus.SESSION_STATUS.PAYMENT_OVERPAID,
    ];

    static readonly ERROR_STATUS = [
        PaymentStatus.SESSION_STATUS.PAYMENT_UNSUCCESSFUL,
        PaymentStatus.SESSION_STATUS.PAYMENT_CLAIMED,
    ];

    static readonly CANCELLED_STATUS = [
        PaymentStatus.SESSION_STATUS.PAYMENT_CANCELLED,
        PaymentStatus.SESSION_STATUS.PAYMENT_EXPIRED,
    ];

    static readonly WARNING_STATUS = [
        PaymentStatus.SESSION_STATUS.REFUND_WAITING,
        PaymentStatus.SESSION_STATUS.PENDING_VALIDATION,
        PaymentStatus.SESSION_STATUS.PAYMENT_PARTIAL,
        PaymentStatus.SESSION_STATUS.PAYMENT_LATE,
        PaymentStatus.SESSION_STATUS.FRAUD_SHIELD,
    ];

    static readonly INFO_STATUS = [
        PaymentStatus.SESSION_STATUS.IBAN_REQUIRED,
        PaymentStatus.SESSION_STATUS.PAYMENT_PLANNED,
        PaymentStatus.SESSION_STATUS.PAYMENT_WAITING,
        PaymentStatus.SESSION_STATUS.PAYMENT_PENDING,
        PaymentStatus.SESSION_STATUS.PAYMENT_PENDING_IMMEDIAT_TRANSFER,
        PaymentStatus.SESSION_STATUS.PAYMENT_PENDING_SMART_TRANSFER,
    ];
}

export const FRAUDULENT_CASES = {
    FRAUD_SUSPECTED: 'fraud_suspected', // regular cases
    FRAUD_SUSPECTED_AUTOREFUND: 'fraud_suspected_autorefunded', // when an auto refund is requested - i.e., for the original PPS
    FRAUD_REFUND: 'fraud_refund', // set for the actual fraud refund PPS
    FRAUD_SUSPECTED_NO_OVERRIDE: 'fraud_suspected_no_override', // fraud detected but we were not able to block the transaction
};
