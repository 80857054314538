import { ClassicActivationStatus } from '../constants/classic-activation-status-constants';
import { OnboardingStatus } from '../constants/onboardings-status-constants';

type Campaign = {
    utmSource: string,
    utmCampaign: string
}

export class Company {
    id: string;
    name: string;
    website: string;
    functionalities: string[];
    registrationNumber: string;
    companyAddressStreet: string;
    companyAddressNumber: string;
    postCode: string;
    city: string;
    country: string;
    averageBasketPerBuyer: string;
    estimatedAverageMonthlyTransactionVolume: string;
    sectorDescription: string;
    sectorSubcategoryDescription?: string;
    customSectorDescription: string;
    companySizeGroup?: 'CL' | 'MidMarket' | 'SMB';
    config: {
        refundEmail: string,
        hideRfpLinkForNonAdmin: boolean,
        rtpPrefix: string,
        payoutLimit: number,
    };
    status: {
        onboarding: OnboardingStatus
        classicActivation: ClassicActivationStatus
    };
    legalRepresentative: {
        firstName: string,
        lastName: string,
        phone: string,
        email: string
    };
    isSsoCompany: boolean;
    VATNumber: string;
    ssoMultiCompanyAccess?: boolean;
    ssoDomains?: string[];
    campaigns?: Campaign[];
    createdAt?: Date;
    updatedAt?: Date;

    constructor(obj: any) {
        this.id = obj.id;
        this.name = obj.name;
        this.website = obj.website;
        this.functionalities = obj.functionalities;
        this.registrationNumber = obj.registrationNumber;
        this.companyAddressStreet = obj.companyAddressStreet;
        this.companyAddressNumber = obj.companyAddressNumber;
        this.postCode = obj.postCode;
        this.city = obj.city;
        this.country = obj.country;
        this.averageBasketPerBuyer = obj.averageBasketPerBuyer;
        this.estimatedAverageMonthlyTransactionVolume = obj.estimatedAverageMonthlyTransactionVolume;
        this.sectorDescription = obj.sectorDescription;
        this.sectorSubcategoryDescription = obj.sectorSubcategoryDescription;
        this.customSectorDescription = obj.customSectorDescription;
        this.companySizeGroup = obj.companySizeGroup;
        this.config = {
            refundEmail: obj.config?.refundEmail,
            hideRfpLinkForNonAdmin: obj.config?.hideRfpLinkForNonAdmin,
            rtpPrefix: obj.config?.rtpPrefix,
            payoutLimit: obj.config?.payoutLimit,
        };
        this.status = {
            onboarding: obj.status?.onboarding,
            classicActivation: obj.status?.classic_activation,
        },
        this.legalRepresentative = obj.legalRepresentative;
        this.isSsoCompany = obj.is_sso_company;
        this.ssoMultiCompanyAccess = obj.sso_multi_company_access;
        this.ssoDomains = obj.sso_domains;
        this.campaigns = this._mapCampaigns(obj.campaigns);
        this.VATNumber = obj.VATNumber;
        this.createdAt = obj.createdAt || null;
        this.updatedAt = obj.updatedAt || null;
    }

    isSuspended(): boolean {
        return this.isClassicActivationSuspended() || this.isOnboardingSuspended();
    }

    isClassicActivationSuspended(): boolean {
        return this.status.classicActivation === ClassicActivationStatus.SUSPENDED;
    }

    isOnboardingSuspended(): boolean {
        return this.status.onboarding === OnboardingStatus.SUSPENDED;
    }

    isChurned(): boolean {
        return this.status.classicActivation === ClassicActivationStatus.CHURNED;
    }
    isActivated(): boolean {
        return this.status.onboarding === OnboardingStatus.CLASSIC_ONBOARDING;
    }

    isActivationIncomplete(): boolean {
        return this.status.onboarding === OnboardingStatus.INCOMPLETE;
    }

    isActivationBlocked(): boolean {
        return ['refused', 'suspended'].includes(this.status.onboarding);
    }

    private _mapCampaigns(campaigns: any[]): Campaign[] {
        if (!campaigns) {
            return [];
        }

        return campaigns.map(campaign => ({
            utmSource: campaign.utm_source,
            utmCampaign: campaign.utm_campaign
        }));
    }
}
