import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { signOut } from 'firebase/auth';
import { Router } from '@angular/router';

declare type StorageRow = {
    key: string,
    value: string
};

const SESSION_REQUEST_KEY = 'SESSION_REQUEST';
const SESSION_SHARE_KEY = 'SESSION_SHARE';
const SESSION_END_KEY = 'SESSION_END';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private sessionInfo: StorageRow;

    constructor(
        private fireAuth: Auth,
        private router: Router,
    ) {
        this.initSession();
    }

    private initSession(): void {
        this.getSessionInfo();
        this.initEventsHandler();
        this.requestSession();
    }

    shareSession(): void {
        if (this.sessionInfo) {
            localStorage.setItem(SESSION_SHARE_KEY, JSON.stringify(this.sessionInfo));
            localStorage.removeItem(SESSION_SHARE_KEY);
        }
    }

    endSession(target: string = null): void {
        localStorage.setItem(SESSION_END_KEY, '1');
        localStorage.removeItem(SESSION_END_KEY);
        this.clearSession(target);
    }

    isSessionActive(): boolean {
        return !!this.sessionInfo;
    }

    public getSessionInfo(): void {
        const key = Object.keys(sessionStorage).find(k => k.startsWith('firebase:authUser'));
        if (key) {
            const value = sessionStorage.getItem(key);
            this.sessionInfo = { key, value };
        }
    }

    private initEventsHandler(): void {
        window.addEventListener('storage', (event) => {
            if (!event.newValue) { return; }

            switch (event.key) {
                case SESSION_REQUEST_KEY:
                    this.shareSession();
                    break;

                case SESSION_SHARE_KEY:
                    this.setSession(event.newValue);
                    break;

                case SESSION_END_KEY:
                    this.clearSession();
                    break;
            }
        });
    }

    private requestSession(): void {
        if (!this.sessionInfo) {
            localStorage.setItem(SESSION_REQUEST_KEY, '1');
            localStorage.removeItem(SESSION_REQUEST_KEY);
        }
    }

    private setSession(sessionInfo: string): void {
        if (!this.sessionInfo) {
            const response = JSON.parse(sessionInfo);
            const { key, value } = response;
            sessionStorage.setItem(key, value);
            this.sessionInfo = response;
        }
    }

    private clearSession(target: string = null): void {
        if (this.sessionInfo) {
            this.sessionInfo = null;
            signOut(this.fireAuth).then(
                () => this.router.navigateByUrl(target || '/auth/login')
            );

        }
    }

}
