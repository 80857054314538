import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { APP_CONFIG } from './app.config';
import { AppModule } from './app/app.module';
import { initializeDatadog } from './datadog';

fetch('/assets/config.json')
    .then((response) => response.json())
    .then((config) => {
        initializeDatadog(config);
        if (config.production) {
            enableProdMode();
        }

        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    })
    .catch((error) => console.error('Could not load config.json', error));
