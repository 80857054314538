import { Injectable, Inject } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AuthService } from '../services/auth/auth.service';
import { finalize, switchMap } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
    private auth: AuthService,
    private loading: LoadingService,
    @Inject(APP_CONFIG) private config: AppConfig,
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        let handler = next.handle(request);

        if (request.url.startsWith('/api')) {
            handler = this._getAuthHandler(request, next);
            handler = this._getLoadingHandler(handler);
        }

        return handler;
    }

    private _getAuthHandler(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return this.auth.getUserTokenId().pipe(
            switchMap((tokenId) => {
                const headers: any = {};
                if (tokenId) {
                    headers['Authorization'] = `Bearer ${tokenId}`;
                }

                request = request.clone({
                    url: request.url.replace(/^\/api/, this.config.api_url),
                    setHeaders: headers,
                });

                return next.handle(request);
            }),
        );
    }

    private _getLoadingHandler(
        handler: Observable<HttpEvent<unknown>>,
    ): Observable<HttpEvent<unknown>> {
        this.loading.addRequest();

        return handler.pipe(finalize(() => this.loading.removeRequest()));
    }
}
